import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

export default function CopyWidget(props) {
  const { copyText } = props;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500); // Reset after 1.5 seconds
  };

  return (
    <div className="ml-1">
      <CopyToClipboard text={copyText} onCopy={handleCopy}>
        <button>
          {isCopied ? <i class="fa-solid fa-copy"></i> : <i class="fa-regular fa-copy"></i>}
        </button>
      </CopyToClipboard>
    </div>
  );
}